import React from 'react';

import {
    BusinessAccessories,
    HighPerformance,
    Everyday,
    EntryLevel,
    Toner,
    Paper,
    Printhead,
    LaserjetMaintenance,
    SamsungPrinter,
    Laptop,
    Workspace,
    Printers,
    Tower,
    HighPerformanceSec,
    BestSellers,
    Monitors,
    Networking,
    Creators,
    Issues,
    Batteries,
    CarePacksSec,
    MiceKeyboards,
    Multimedia,
    ThinClients,
    Workstation,
    Departments,
    LargeFormatPrinters,
    HomeOffice,
    Workgroups,
    SmallWorkTeams,
    SmallBusiness,
    BusinessPrinter,
    Software,
    Smarthome,
    Docking,
    PrinterAccessories,
    Storage,
    Headband,
    Cables,
    TonerCartridge,
    BagsAndCases,
    InkCartridges,
    Calculator,
    NewArrivals,
    SpeakerPhones,
    Webcams,
    Microphones,
    DeliveryTruck,
    Cancel,
    Globe,
    Folder,
    Badge,
    CrossCircle,
    Shipping,
    BusinessPhone,
    SpeakerPhone,
    VideoConferencing,
    Headset,
    HpLogo,
    StylusPens,
} from '@hpstellar/icons';
import { BundleBoxes, Server, Hardware } from '@hpstellar/vis-icons';
import IconMapper from './icon-mapper';

const matchIcon = (value, term, substringMatch) => {
    if (!value || !term) return false;
    const termList = !Array.isArray(term) ? [term] : term;
    //check if value is qual to term with and without spaces
    return termList.reduce((acc, term) => {
        const lcValue = value.toLowerCase();
        const lcTerm = term.toLowerCase();
        const lcTermNoSpace = lcTerm.replace(/\s/g, '');
        return (
            acc ||
            lcValue === lcTerm ||
            lcValue === lcTermNoSpace ||
            lcValue == lcTermNoSpace.replace('&', 'and') ||
            (substringMatch && lcValue.indexOf(lcTerm) > -1)
        );
    }, false);
};

// eslint-disable-next-line import/prefer-default-export
export const getIcon = (value = '', category, size = 'l') => {
    const icon = value.toLowerCase();

    switch (true) {
        case /server/gi.test(icon):
            return <Server size={size} />;
        //match new and arrival or arrived
        case /(new|just).*arriv(ed|al)/gi.test(icon):
            return <NewArrivals />;
        case icon === 'software':
            return <Software size={size} />;
        case matchIcon(icon, ['smart devices', 'smart home'], true):
            return <Smarthome size={size} />;
        case icon === 'docking stations':
            return <Docking />;
        case matchIcon(icon, 'printer accessories'):
            return <PrinterAccessories />;
        case icon === 'storage':
            return <Storage />;
        case icon === 'gaming accessories' || icon === 'gaming':
            return <Headband />;
        case matchIcon(icon, ['cables'], true):
            return <Cables />;
        case matchIcon(icon, ['components'], true):
            return <Hardware />;
        case icon === 'toner cartridges':
            return <TonerCartridge />;
        case matchIcon(icon, ['bags & sleeves', 'bags & cases']):
            return <BagsAndCases />;
        case matchIcon(icon, ['business', 'business accessories', 'workspace accessories']):
            return <BusinessAccessories size={size} />;
        case icon === 'premium':
            return <HighPerformance size={size} />;
        case icon === 'everyday':
            return <Everyday size={size} />;
        //TODO: ask mapping
        // case icon === 'great value':
        //     return <Everyday size={size} />;
        case icon === 'entry level':
            return <EntryLevel size={size} />;

        case icon === 'ink cartridges':
            return <InkCartridges size={size} />;
        case icon === 'toner cartridges':
            return <Toner size={size} />;
        case icon === 'paper':
            return <Paper size={size} />;
        case icon === 'printheads':
            return <Printhead size={size} />;
        case icon === 'laserjet maintenance':
            return <LaserjetMaintenance size={size} />;
        case icon === 'samsung toner':
            return <SamsungPrinter size={size} />;

        case icon === 'laptop care packs':
            return <Laptop size={size} />;
        case icon === 'desktop care packs':
            return <Workspace size={size} />;
        case icon === 'printer care packs':
            return <Printers size={size} />;
        case icon.indexOf('business pc care packs') > 0:
            return <BusinessAccessories size={size} />;

        case icon === 'towers':
            return <Tower size={size} />;
        case icon === 'all-in-ones':
            return <Workspace size={size} />;
        case icon === 'high performance':
            return <HighPerformanceSec size={size} />;
        case icon === 'great value':
            return <BestSellers size={size} />;

        case icon.indexOf('home') > -1 && category === 'printers':
            return <Printers size={size} />;

        case icon === 'home & home office':
            return <HomeOffice size={size} />;
        case icon === 'business personal':
            return <SmallBusiness size={size} />;
        case icon === 'small work teams':
            return <SmallWorkTeams size={size} />;
        case icon === 'workgroups':
            return <Workgroups size={size} />;
        case icon === 'departments':
            return <Departments size={size} />;
        case icon === 'large format printers':
            return <LargeFormatPrinters size={size} />;

        case icon === 'workstations & thin clients':
            return <Workspace size={size} />;
        case icon === 'workstations':
            return <Workstation size={size} />;
        case icon === 'thin clients':
            return <ThinClients size={size} />;
        case icon === 'laptops':
            return <Laptop size={size} />;
        case icon === 'desktops':
            return <Workspace size={size} />;
        case icon === 'printers':
            return <Printers size={size} />;
        case icon === 'monitors & accessories' || icon === 'monitors' || icon === 'monitor':
            return <Monitors size={size} />;
        case icon === 'networking':
            return <Networking size={size} />;
        case icon === 'tech support services':
            return <Issues size={size} />;
        case icon === 'creators' || icon === 'productivity':
            return <Creators size={size} />;
        case matchIcon(icon, ['chargers & power adapters', 'cables & adapters', 'batteries']):
            return <Batteries size={size} />;
        case matchIcon(icon, 'care packs'):
            return <CarePacksSec size={size} />;
        case icon === 'mice & keyboards' || (icon.indexOf('mice') > -1 && icon.indexOf('keyboards') > -1):
            return <MiceKeyboards size={size} />;
        case icon === 'audio, video & multimedia' || icon.indexOf('multimedia') > -1:
            return <Multimedia size={size} />;
        case icon.indexOf('business') > -1 && category === 'printers':
            return <SmallBusiness size={size} />;
        case icon.indexOf('enterprise') > -1 && category === 'printers':
            return <BusinessPrinter size={size} />;
        case icon.indexOf('large format') > -1 && category === 'printers':
            return <LargeFormatPrinters size={size} />;
        case icon.indexOf('docking') > -1:
            return <Docking />;
        case icon.indexOf('paper') > -1:
            return <Paper size={size} />;
        case icon.indexOf('calculator') > -1:
            return <Calculator size={size} />;
        case icon.indexOf('webcam') > -1:
            return <Webcams size={size} />;
        case icon.indexOf('microphone') > -1:
            return <Microphones size={size} />;
        case icon.indexOf('speakerphone') > -1:
            return <SpeakerPhone size={size} />;
        case icon.indexOf('speaker') > -1:
            return <SpeakerPhones size={size} />;
        case icon.indexOf('deliverytruck') > -1:
            return <DeliveryTruck size="m" />;
        case icon.indexOf('cancel') > -1:
            return <Cancel size="m" />;
        case icon.indexOf('globe') > -1:
            return <Globe size="m" />;
        case icon.indexOf('folder') > -1:
            return <Folder size="m" />;
        case icon.indexOf('badge') > -1:
            return <Badge size="m" />;
        case icon.indexOf('shipping') > -1:
            return <Shipping size="m" />;
        case icon.indexOf('business') > -1 && icon.indexOf('phone') > -1:
            return <BusinessPhone size={size} />;
        case icon.indexOf('video') > -1 && icon.indexOf('conferencing') > -1:
            return <VideoConferencing size={size} />;
        case icon.indexOf('headset') > -1 || icon.indexOf('headphones') > -1:
            return <Headset size="m" />;
        case (icon.indexOf('most') > -1 && icon.indexOf('popular') > -1) || icon.indexOf('recommend') > -1:
            return <HpLogo size={size} />;
        case icon.indexOf('bundle') > -1:
            return <IconMapper name={'bundles'} size={size} />;
        case matchIcon(icon, 'stylus pens'):
            return <StylusPens size={size} />;
        case icon.indexOf('accessories') > -1:
            return <MiceKeyboards />;
        default:
            return <CrossCircle size="s" />;
    }
};

export const isSVG = stringValue => {
    if (!stringValue || typeof stringValue !== 'string') {
        return false;
    }
    return /(<svg)([^<]*|[^>]*)/.test(stringValue);
};

export const isImageSrc = stringValue => {
    if (!stringValue || typeof stringValue !== 'string') {
        return false;
    }
    return /[\/.](gif|jpg|jpeg|tiff|png|svg)($|\?|\#)/gi.test(stringValue);
};

export const getIconWithSVG = options => {
    const { icon, category, size, className = 'svg-icon' } = options || {};
    return isSVG(icon) ? (
        <span className={className} dangerouslySetInnerHTML={Helpers.createMarkup(icon)} />
    ) : (
        getIcon(icon, category, size)
    );
};
